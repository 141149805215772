// eslint-disable-next-line
<template>
  <h1>Login</h1>
</template>

<script>
export default {}
</script>

<style>
body{
    font-size: 16px;
}
</style>
